<template>
  <div class="page">
    <Tables
      ref="xTable"
      :tableName="tableName"
      :tableData="tableData"
      :totalPage="totalPage"
      :isLoading="isLoading"
      :searchFormData="searchFormData"
      :showRefresh="true"
      :showSearch="true"
      :showAdd="$hasAccess('market.plan/add')"
      addName="添加广告"
      @getList="getList"
      @clearSearch="clearSearch"
      @addItemInTable="addOne"
    >

      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-select v-model="searchFormData.position" placeholder="按广告位置查询" clearable size="small">
          <el-option label="支付后页面" :value="10">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item slot="search-item">
        <el-select
                v-model="searchFormData.group_id"
                filterable
                size="small"
                reserve-keyword
                clearable
                placeholder="请选择小区"
                v-if="$hasAccess('device.group/view')"
                :remote-method="remoteGroup"
                :loading="groupLoading">
          <el-option
                  v-for="item in groupList"
                  :key="item.group_id"
                  :label="item.group_name"
                  :value="item.group_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item slot="search-item">
        <el-date-picker class="from-item-width"
                        size="small"
                        unlink-panels
                        v-model="dates"
                        type="daterange"
                        clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">

        </el-date-picker>
      </el-form-item>
      <el-form-item slot="search-item">
        <el-select
                v-model="searchFormData.status"
                size="small"
                clearable
                placeholder="按广告状态查询"
        >
          <el-option label="进行中" :value="10">
          </el-option>
          <el-option label="已关闭" :value="20">
          </el-option>
          <el-option label="已完成" :value="30">
          </el-option>
        </el-select>
      </el-form-item>
      <!--表格内容-->
      <vxe-table-column
        slot="table-item"
        field="position"
        title="广告位置"
        align="center"
        min-width="100px"
      >
        <template v-slot="{ row }">
          <span>{{types.position[row.position]||'未知类型'}}</span>
        </template>

      </vxe-table-column>
      <vxe-table-column
              slot="table-item"
              field="content"
              title="广告内容"
              align="center"
              min-width="80px"
      />
      <vxe-table-column
              slot="table-item"
              title="广告图片"
              align="center"
      >
        <template v-slot="{ row }">
          <el-image
                  v-if="row.suffix === 'image'"
                  style="width: 100px; height: 48px"
                  :src="row.url"
                  :preview-src-list="viewImgList(row)"
                  fit="contain"
          >
          </el-image>
          <el-link
                  v-else
                  type="success"
                  :href="row.url"
                  style="text-decoration:underline"
          >
            {{row.name}}
          </el-link>
        </template>
      </vxe-table-column>
      <vxe-table-column
              slot="table-item"
              field="group_name"
              title="发放小区"
              align="center"
              min-width="80px"
      />
      <vxe-table-column
              slot="table-item"
              field="start_time"
              title="开始时间"
              align="center"
              min-width="80px"
      />
      <vxe-table-column
              slot="table-item"
              field="end_time"
              title="结束时间"
              align="center"
              min-width="80px"
      />
      <vxe-table-column
        slot="table-item"
        field="status"
        title="广告状态"
        align="center"
      >
        <template v-slot="{ row }">
          <span>{{types.plan_type[row.status]||'未知类型'}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        title="操作"
        align="center"
        width="260"
      >
        <template v-slot="{ row }">
          <el-button
            size="small"
            plain
            @click="editOne(row)"
            v-if="$hasAccess('adverts.adlist/view')"
          >编辑</el-button>
          <el-button
                  size="small"
                  plain
                  @click="upStatus(row)"
                  v-if="$hasAccess('adverts.adlist/upstatus')"
          ><span>{{types.status[row.status]}}</span></el-button>
          <el-button
            size="small"
            plain
            @click="deleteOne(row)"
          >删除</el-button>
        </template>
      </vxe-table-column>
    </Tables>

    <!-- 新增 -->
    <el-dialog
      title="添加广告"
      :visible.sync="addDialogShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
    >
      <el-form
        :model="addDialogFormData"
        :rules="rules"
        ref="addForm"
        label-width="100px"
        class="form"
      >
        <el-form-item
          label="广告内容"
          prop="content"
        >
          <el-input
            v-model="addDialogFormData.content"
            style="width: 80%;"
          ></el-input>
        </el-form-item>
        <el-form-item
                label="广告位置"
                prop="position"
        >
            <el-select v-model="addDialogFormData.position" placeholder="广告位置" clearable size="small">
                <el-option label="支付后页面" :value="10">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="弹出次数" prop="viewtype">
            <el-select v-model="addDialogFormData.viewtype" placeholder="弹出次数" clearable size="small">
                <el-option v-for="(item,index) in types.viewtype" :label="item" :value="index">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item
          label="发放小区"
          prop="group_id"
        >
            <el-select
                    v-model="addDialogFormData.group_id"
                    filterable
                    size="small"
                    multiple
                    filterable
                    remote
                    reserve-keyword
                    clearable
                    placeholder="请选择小区"
                    v-if="$hasAccess('device.group/view')"
                    :remote-method="remoteGroup"
                    :loading="groupLoading">
                <el-option
                        v-for="item in groupList"
                        :key="item.group_id"
                        :label="item.group_name"
                        :value="item.group_id">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item
          label="时间区间"
          prop="select_time"
        >
          <el-date-picker class="from-item-width" unlink-panels v-model="addDialogFormData.select_time" type="daterange" clearable range-separator="至" start-placeholder="广告开始日期" end-placeholder="广告结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item
                label="图片尺寸"
                prop="plan_name"
        >
            <label>415*270px(宽*高)<span style="color: red">(最大100kb)</span></label>
        </el-form-item>
        <el-form-item
                label="图片上传"
                prop="plan_name"
        >
          <upload :fileList="imageList" @getFileInfo="getFileInfo">
          </upload>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-check"
          @click="saveAdd()"
          v-if="$hasAccess('market.plan/add')"
        >保 存
        </el-button>
        <el-button
          size="small"
          plain
          icon="el-icon-close"
          @click="closeDialog"
        >取 消
        </el-button>
      </div>
    </el-dialog>

    <!-- 编辑 -->
    <el-dialog
      title="编辑广告信息"
      :visible.sync="editDialogShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
    >
      <el-form
        :model="editDialogFormData"
        :rules="rules"
        ref="editForm"
        label-width="100px"
        class="form"
      >
        <el-form-item
          label="广告内容"
          prop="content"
        >
          <el-input
            v-model="editDialogFormData.content"
            style="width: 80%;"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="广告位置"
          prop="plan_type"
        >
          <el-radio-group v-model="editDialogFormData.position">
            <el-radio :label="10">支付后页面</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="发放小区"
          prop="group_id"
        >
          <el-select
                  v-model="editDialogFormData.group_id"
                  filterable
                  size="small"
                  reserve-keyword
                  clearable
                  placeholder="请选择小区"
                  v-if="$hasAccess('device.group/view')"
                  :remote-method="remoteGroup"
                  :loading="groupLoading">
            <el-option
                    v-for="item in groupList"
                    :key="item.group_id"
                    :label="item.group_name"
                    :value="item.group_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="时间区间"
          prop="select_time"
        >
          <el-date-picker class="from-item-width" style="width: 100%" unlink-panels v-model="editDialogFormData.select_time" type="daterange" clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item
                label="图片尺寸"
                prop="plan_name"
        >
          <label>415*270px(宽*高)<span style="color: red">(最大100kb)</span></label>
        </el-form-item>
        <el-form-item
                label="图片上传"
                prop="plan_name"
        >
          <upload :fileList="imageList" @getFileInfo="getFileInfoEdit">
          </upload>
        </el-form-item>

        <el-form-item label="广告状态" prop="status">
          <el-radio-group v-model="editDialogFormData.status">
            <el-radio :label="10">进行中</el-radio>
            <el-radio :label="20">已关闭</el-radio>
            <el-radio :label="30">已完成</el-radio>
          </el-radio-group>
        </el-form-item>


      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-check"
          @click="saveEdit()"
          :disabled="editDisabled"
          v-if="$hasAccess('adverts.adlist/save')"
        >保 存 编 辑
        </el-button>
        <el-button
          size="small"
          plain
          icon="el-icon-close"
          @click="closeDialog"
        >取 消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Tables from '@/components/tables'
import upload from '@/components/upload'
export default {
  name: 'market-plan-list',
  components: {
    Tables,
    upload
  },
  data () {
    return {
      // 表格信息
      tableName: '广告信息',
      isLoading: false,
      tableData: [],
      totalPage: 0,
      groupList: [],
      dates:[],
      status: 0,
      imageList: [],
      selecttime: {},
      selecttimeedit: {},
      groupLoading:false,
      searchFormData: {},
      types: {
        plan_type: {
          10: '进行中',
          20: '已关闭',
          30: '已完成'
        },
        position: {
          10: '支付后页面',
          20: '充电记录页面'
        },
        status: {
          10: '关闭',
          20: '开启',
          30:"关闭"
        },
        viewtype: {
          1:"每次打开",
          2:"每天1次",
          3:"仅1次"
        }
      },
      // 新增
      addDialogShow: false,
      addDialogFormData: {},
      rules: {
        content: [
          { required: true, message: '请输入广告内容', trigger: 'change' }
        ],
          position: [
          { required: true, message: '请选择广告位置', trigger: 'change' }
        ],
        group_id: [
          { required: true, message: '请选择发放小区', trigger: 'change' }
        ]
      },
      // 编辑
      editDialogShow: false,
      editDialogFormData: {},
      editDialogFormOriData: {},
      editNeedData: ['ad_id', 'content', 'position', 'group_id', 'select_time','status']
    }
  },
  computed: {
    editDisabled () {
      return false
    },   viewImgList () {
      return function (row) {
        return [row.url]
      }
    },
  },
  mounted () {
    this.init();
  },
  activated () {
    this.init();
  },
  methods: {
    // 初始化请求
    init() {
      this.remoteGroup();
    },
    // 表格列表
    async getList (currentPage, pageSize) {
      if (this.dates && this.dates.length == 2) {
        this.searchFormData.start_time = parseInt(this.dates[0].getTime() / 1000);
        this.searchFormData.end_time = parseInt(this.dates[1].getTime() / 1000);
      } else {
        this.searchFormData.start_time = '';
        this.searchFormData.end_time = '';
      }
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize
      }
      this.isLoading = true
      const searchData = this.searchFormData
      const res = await this.$api.Ad.GetAdList(params, searchData)
      this.tableData = res.data
      this.totalPage = res.total
      this.isLoading = false
    },
    async remoteGroup (query) {
      if (query !== '') {
        this.groupLoading = true
        const params = {
          token: this.$store.state.user.token,
          page: 1,
          size: 1000
        }
        const res = await this.$api.Device.GetGroupList(params, {
          group_name: query
        })
        this.groupList = res.data
        this.groupLoading = false
      } else {
        this.groupList = []
      }
    },
    // 重置搜索
    clearSearch () {
      this.dates = [];
      this.searchFormData = {}
    },
    // 附件
    getFileInfo(fileInfo) {
      this.addDialogFormData.attach_id = fileInfo;
    },
    // 附件
    getFileInfoEdit(fileInfo) {
      this.editDialogFormData.attach_id_new = fileInfo;
    },
    // 重置form
    resetFormData () {
      this.dates = [];
      this.addDialogFormData = {
        plan_type: 10
      }
      this.imageList = [];
      this.editDialogFormData = {}
      this.editDialogFormOriData = {}
      if (this.$refs.addForm) {
        this.$refs.addForm.resetFields()
      }
      if (this.$refs.editForm) {
        this.$refs.editForm.resetFields()
      }
    },
    // 新增
    addOne () {
      this.resetFormData()
      this.addDialogShow = true
    },
    // 编辑
    async editOne (row) {
      this.resetFormData()
      const params = {
        token: this.$store.state.user.token,
        ad_id: row.ad_id
      }
      const res = await this.$api.Ad.GetAdDetail(params);
      if (res) {
        const { ad_id, content, position, group_id, select_time,status,attach_id } = res
        this.editDialogFormData = { ad_id, content, position, group_id, select_time ,status ,attach_id}
        this.editDialogFormOriData = {ad_id, content, position, group_id, select_time,status ,attach_id}
      }
      this.editDialogShow = true
    },
    async upStatus (row) {
      if (row.status == 10) {
        this.status = 20
      } else if (row.status == 20){
        this.status = 10
      }else {
        this.status = 20
      }
      const params = {
        token: this.$store.state.user.token,
        ad_id: row.ad_id,
        status:this.status
      }
      const res = await this.$api.Ad.upStatus(params);
      this.$notify({
        title: '成功',
        message: '更改状态成功',
        type: 'success'
      })
      this.$refs.xTable.refreshTable()
    },
    // 删除
    deleteOne (row) {
      this.$confirm('是否确定删除该条广告?', '提示', {
        cancelButtonClass: 'btn-custom-cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const params = {
          token: this.$store.state.user.token,
          ad_id: row.ad_id,
          attach_id:row.attach_id
        }
        await this.$api.Ad.DeleteAd(params)
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        })
        this.$refs.xTable.refreshTable()
      })
    },
    // 关闭弹窗
    closeDialog () {
      this.addDialogShow = false
      this.editDialogShow = false
    },
    // 新增保存
    saveAdd () {
      if (!this.addDialogFormData.group_id) {
        this.$refs.addForm.validateField('group_id');
        return;
      }
      if (!this.addDialogFormData.content) {
        this.$refs.addForm.validateField('content');
        return;
      }
      if (!this.addDialogFormData.position) {
        this.$refs.addForm.validateField('position');
        return;
      }
      if (!this.addDialogFormData.select_time || this.addDialogFormData.select_time.length != 2) {
        this.$message.warning('请选择时间段')
        return;
      }
      if (!this.addDialogFormData.attach_id) {
        this.$message.warning('请上传图片')
        return;
      };
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.addDialogFormData.group_id == this.tableData[i].group_id&&this.addDialogFormData.position == this.tableData[i].position) {
          this.$confirm('当前小区已有广告，若再次添加会覆盖前广告。是否确定添加？', '提示', {
            cancelButtonClass: 'btn-custom-cancel',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            this.addAd();
          })
          return;
        }
      }
      this.addAd();
    },
    // 提交记录
    async addAd() {
      this.selecttime.start_time = parseInt(this.addDialogFormData.select_time[0].getTime() / 1000);
      this.selecttime.end_time = parseInt(this.addDialogFormData.select_time[1].getTime() / 1000);
      const form = {
        group_id: this.addDialogFormData.group_id,
        content: this.addDialogFormData.content,
        position: this.addDialogFormData.position,
        attach_id: this.addDialogFormData.attach_id,
        start_time:this.selecttime.start_time,
        end_time:this.selecttime.end_time
      }
      const params = {
        token: this.$store.state.user.token,
        form: JSON.stringify(form),
      }
      await this.$api.Ad.AddAd(params);
      this.$notify({
        title: "成功",
        message: "添加成功",
        type: "success",
      });
      this.closeDialog();
      this.getList();
    },
    // 编辑保存
    saveEdit () {
      if (!this.editDialogFormData.group_id) {
        this.$refs.editForm.validateField('group_id');
        return;
      }
      if (!this.editDialogFormData.content) {
        this.$refs.editForm.validateField('content');
        return;
      }
      if (!this.editDialogFormData.position) {
        this.$refs.editForm.validateField('position');
        return;
      }
      if (!this.editDialogFormData.position) {
        this.$refs.editForm.validateField('status');
        return;
      }
      if (!this.editDialogFormData.select_time || this.editDialogFormData.select_time.length != 2) {
        this.$message.warning('请选择时间段')
        return;
      }
      this.$refs.editForm.validate(async valid => {
        if (this.editDialogFormData.select_time[0].length==undefined){
          this.editDialogFormData.start_time = parseInt(this.editDialogFormData.select_time[0].getTime() / 1000);
          this.editDialogFormData.end_time = parseInt(this.editDialogFormData.select_time[1].getTime() / 1000);
        }else {
          this.editDialogFormData.start_time = this.editDialogFormData.select_time[0];
          this.editDialogFormData.end_time = this.editDialogFormData.select_time[1];
        }
        if (valid) {
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.editDialogFormData)
          }
          await this.$api.Ad.editGroupBill(params)
          this.$notify({
            title: '成功',
            message: '编辑成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
